import {Box, CircularProgress} from '@mui/material'
import { experimentalStyled } from '@mui/material/styles'
import 'bootstrap/dist/css/bootstrap-grid.min.css'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Redirect, Switch} from 'react-router-dom'
import 'react-sortable-tree/style.css' // This only needs to be imported once in your app
import { IS_PRODUCTION, routes } from 'src/constants'
import { setFonts } from 'src/redux/slices/helperSlice'
import { RootState } from 'src/redux/store'
import { request } from 'src/services/apiServices'
import Home from './components/Home/Home'
import SidebarLayout from './components/SidebarLayout/SidebarLayout'
import ProtectedRoute from './pages/ProtectedRoute'
import { getPermissionsThunk } from './redux/slices/organizationSlice'
import './styles/global.scss'
import UserSettings from './components/Account/UserSettings/UserSettings'
import OrganizationData from './components/Account/OrganizationData/OrganizationData'
import OrganizationMembers from './components/Account/OrganizationMembers/OrganizationMembers'
import Integration from './components/Integration/Integration'
import Dashboard from './pages/Dashboard'
import { LiveStreams } from './components/LiveStreams/LiveStreams'
import Subscription from './components/Account/Subscription/Subscription'
import ShopifyClaim from './components/Integration/ShopifyClaim'
import ConfirmSubscription from './components/Integration/ConfirmSubscription'
import { CreatorsDashboard } from './pages/CreatorOverview/CreatorsDashboard'
import { CreatorsList } from './pages/CreatorsList/CreatorsList'
import { CreatorExperienceList } from './pages/ExperienceList/CreatorExperienceList'
import { Gallery } from './components/Gallery/Gallery'
import { Brands } from './components/Brands/Brands'
import { ExperienceList } from './components/Experiences/ExperienceList'
import { Autopilot } from './components/Autopilot/Autopilot'
import InstagramHandleCode from './components/Integration/InstagramHandleCode'
import InstagramDeauthorize from './components/Integration/InstagramDeauthorize'
import { LiveStreamsLiveRoom } from './components/LiveStreams/LiveStreamsLiveRoom'
import { Creators } from './components/Creators/Creators'
import {CreatorLivestreams} from "./components/Creators/CreatorLivestreams";
import {AdminOrganizations} from "./components/Admin/AdminOrganizations";
import {AdminPlatformActions} from "./components/Admin/AdminPlatformActions";

const MainWrapper = experimentalStyled(Box)(
  () => `
    flex: 1;
    display: flex;
    height: 100%;
`,
)

function App() {

  const dispatch = useDispatch()

  useEffect(() => {
    const script = document.createElement('script')
    if (IS_PRODUCTION) {
      script.src =
        'https://api.contester.net/api/public/scripts/experienceCollections/5d9019a0-7c45-47a6-82a6-cc1f3ca2f37d/js?spa=true'
    } else {
      script.src =
        'https://staging-api.contester.net/api/public/scripts/experienceCollections/6c2afb3d-0681-4fc3-aba7-836a9685a20f/js?spa=true'
    }
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

//   useEffect(() => {
//     const script = document.createElement('script')
//     script.innerHTML = `
//     <!-- Start of LiveChat (www.livechat.com) code -->
//     window.__lc = window.__lc || {};
//     window.__lc.license = 15730608;
//     ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))
// `
//     script.async = true
//
//     document.body.appendChild(script)
//
//     return () => {
//       document.body.removeChild(script)
//     }
//   })

  const fonts = useSelector((state: RootState) => state.helperReducer.fonts)
  const [dataLoading, setDataLoading] = useState(true)
  const accessToken = useSelector((state: RootState) => state.authReducer.token)
  const isAuthenticated = useSelector((state: RootState) => state.authReducer.isAuthenticated)

  const permissions = useSelector(
    (state: RootState) => state.organizationReducer.permissions,
  )

  const initState = async () => {
    let res = await request({
      method: 'GET',
      url: routes.GET_FONTS,
    })

    dispatch(setFonts([...res?.fonts]))
    await dispatch(getPermissionsThunk())

    setDataLoading(false)
  }

  useEffect(() => {
    if (isAuthenticated && accessToken) {
      initState()
    }
  }, [isAuthenticated, accessToken])


  return (
    <>
      { (!isAuthenticated || !accessToken || dataLoading) ? (
        <CircularProgress className="circular-progress-center" />
      ) : (
        <>
          {fonts.map(font => {
            return (
              font.url && <style key={font.url}>@import url('{font.url}');</style>
            )
          })}
          <MainWrapper>
            <SidebarLayout>
              <div id="main-content-area">
                <Switch>
                  <ProtectedRoute
                    isAccessAvailable={permissions?.menu?.analytics === true}
                    path="/summary"
                    component={() => <Dashboard />}
                  />
                  <ProtectedRoute
                    isAccessAvailable={
                      permissions?.experience?.createExperience === true
                    }
                    path="/create-content"
                    component={Home}
                  />
                  <ProtectedRoute
                    isAccessAvailable={
                      permissions?.menu?.user ||
                      permissions?.menu?.creatorProfile
                    }
                    path="/account/user-settings"
                    component={UserSettings}
                  />
                  <ProtectedRoute
                    isAccessAvailable={
                      permissions?.menu?.organization === true
                    }
                    path="/account/organization"
                    component={OrganizationData}
                  />
                  <ProtectedRoute
                    isAccessAvailable={permissions?.menu?.team === true}
                    path="/account/team"
                    component={OrganizationMembers}
                  />
                  <ProtectedRoute
                    isAccessAvailable={permissions?.menu?.billing === true}
                    path="/account/billing/subscription"
                    component={Subscription}
                  />
                  <ProtectedRoute
                    isAccessAvailable={
                      permissions?.menu?.integration === true
                    }
                    path="/account/integration"
                    component={Integration}
                  />
                  <ProtectedRoute
                    isAccessAvailable={
                      permissions?.menu?.liveStreams === true
                    }
                    path="/content/livestreams"
                    component={LiveStreams}
                  />
                  <ProtectedRoute
                    isAccessAvailable={
                      permissions?.liveStream?.goLive === true &&
                      permissions?.liveStream?.viewLiveRoom === true
                    }
                    path="/content/liveroom"
                    component={LiveStreamsLiveRoom}
                  />
                  <ProtectedRoute
                    isAccessAvailable={permissions?.menu?.media === true}
                    path="/content/gallery"
                    component={Gallery}
                  />
                  <ProtectedRoute
                    isAccessAvailable={permissions?.menu?.brands === true}
                    path="/content/brands"
                    component={Brands}
                  />
                  <ProtectedRoute
                    isAccessAvailable={
                      permissions?.menu?.experiences === true
                    }
                    path="/content/experiences"
                    component={ExperienceList}
                  />
                  <ProtectedRoute
                    isAccessAvailable={
                      permissions?.menu?.experiences === true
                    }
                    path="/content/autopilot"
                    component={Autopilot}
                  />
                  <ProtectedRoute
                    isAccessAvailable={
                      permissions?.menu?.creatorManagement === true
                    }
                    path="/creators/overview"
                    component={CreatorsDashboard}
                  />
                  <ProtectedRoute
                    isAccessAvailable={
                      permissions?.menu?.creatorManagement === true
                    }
                    path="/creators/list"
                    component={CreatorsList}
                  />
                  <ProtectedRoute
                    isAccessAvailable={
                      permissions?.menu?.creatorManagement === true
                    }
                    path="/creators2"
                    component={Creators}
                  />
                  <ProtectedRoute
                    isAccessAvailable={
                      permissions?.creatorManagement
                        ?.viewCreatorLivestreams === true
                    }
                    path="/creators/livestreams"
                    component={CreatorLivestreams}
                  />
                  <ProtectedRoute
                    isAccessAvailable={
                      permissions?.creatorManagement
                        ?.viewCreatorExperiences === true
                    }
                    path="/creators/experiences"
                    component={CreatorExperienceList}
                  />
                  <ProtectedRoute
                    isAccessAvailable={
                      permissions?.admin?.manageOrganizations === true
                    }
                    path="/admin/organizations"
                    component={AdminOrganizations}
                  />
                  <ProtectedRoute
                    isAccessAvailable={
                      permissions?.admin?.managePlatformActions === true
                    }
                    path="/admin/platformActions"
                    component={AdminPlatformActions}
                  />
                  <ProtectedRoute
                    isAccessAvailable={
                      permissions?.overall?.hasAccess === true
                    }
                    path="/integration/shopify/claim"
                    component={ShopifyClaim}
                  />
                  <ProtectedRoute
                    isAccessAvailable={
                      permissions?.overall?.hasAccess === true
                    }
                    path="/integration/instagram/handleCode"
                    component={InstagramHandleCode}
                  />
                  <ProtectedRoute
                    isAccessAvailable={
                      permissions?.overall?.hasAccess === true
                    }
                    path="/integration/instagram/deauthorize"
                    component={InstagramDeauthorize}
                  />
                  <ProtectedRoute
                    isAccessAvailable={
                      permissions?.overall?.hasAccess === true
                    }
                    path="/integration/confirmSubscription"
                    component={ConfirmSubscription}
                  />
                  <Redirect
                    from="/"
                    to={ permissions?.menu?.analytics
                      ? '/summary' + window.location.search
                      : permissions?.menu?.creatorManagement
                        ? '/creators/overview' + window.location.search
                        : '/account/organization' }
                  />
                </Switch>
              </div>
            </SidebarLayout>
          </MainWrapper>
        </>
      )}
    </>
  )
}

export default App
