import { CircularProgress, Box, Card} from '@mui/material'
import { useEffect, useState } from 'react'
import 'src/components/Dashboard/Dashboard.css'
import SummaryOverview from 'src/components/Dashboard/SummaryOverview'
import UpperCard from 'src/components/Dashboard/UpperCard'
import {
  dashboardGetAvailableBrands, dashboardGetCreatorActivitySummary,
  dashboardGetOrganizationDataChartsByRange,
} from '../api/services'
import {
  BrandData, CreatorActivitySummary, DashboardCharts,
  DashboardGetCreatorDataByRangeQueryParams,
} from '../api/types'
import RecentlyCard from '../components/Dashboard/RecentlyCard'
import SummaryFilter from '../components/Dashboard/SummaryFilter'
import {DateRangeSummaryFilter, defaultFilterDateRange} from "../components/Dashboard/DateRangeSummaryFilter";
import {ExperienceStatisticsSummaryTable} from "../components/Reusable/ExperienceStatisticsSummaryTable";

export type FilterType = 'BRAND' | 'CREATOR' | 'TIMERANGE'

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [filterData, setFilterData] =
    useState<DashboardGetCreatorDataByRangeQueryParams>({
      brandId: undefined,
      ...defaultFilterDateRange
    })

  const [availableBrands, setAvailableBrands] = useState<BrandData[]>([])

  const [creatorActivitySummary, setCreatorActivitySummary] = useState<CreatorActivitySummary>()

  const [dashboardCharts, setDashboardCharts] = useState<DashboardCharts>()

  const getDashbardData = async () => {

    let summary = await dashboardGetCreatorActivitySummary({
      from: filterData.from,
      to: filterData.to,
      brandId: filterData.brandId,
      creatorId: filterData.creatorId,
      experienceId: filterData.experienceId,
      ownerType: "Business",
    })
    setCreatorActivitySummary(summary.data)

    const chartsRes = await dashboardGetOrganizationDataChartsByRange({
      from: filterData.from,
      to: filterData.to,
      brandId: filterData?.brandId,
    })
    setDashboardCharts(chartsRes.data)
    setIsLoading(false)
  }

  // value any because we can't reuse type from generated types
  const handleFilterDataChange = (type: FilterType, value: any) => {
    if (type === 'TIMERANGE') {
      setFilterData({
        ...filterData,
        from: value.start,
        to: value.end,
      })
    }
    if (type === 'BRAND') {
      setFilterData({
        ...filterData,
        brandId: value,
      })
    }
  }

  const getAvailableBrands = async () => {
    const res = await dashboardGetAvailableBrands()
    setAvailableBrands(res?.data)
  }

  useEffect(() => {
    getAvailableBrands()
    getDashbardData()
  }, [filterData])

  if (isLoading) {
    return <CircularProgress />
  }
  return (
    <Box className="dashboard" display="flex" flexDirection={{xs: 'column', md: 'row'}}>
      <Box className="sticky-left-bar" sx={{height: {xs: 'auto', md: '300px'}}} position={{xs: 'relative', md: 'sticky'}}>
        { creatorActivitySummary && (
          <>
            <UpperCard
              title="Account"
              fields={[
                {
                  key: 'Licence',
                  value: creatorActivitySummary?.accountSummary?.licenseTier,
                },

                {
                  key: 'Domain integration',
                  value:
                    creatorActivitySummary?.accountSummary?.origins?.current?.toString(),
                },
                {
                  key: 'Brands and collection',
                  value: creatorActivitySummary?.accountSummary?.brands?.current?.toString(),
                },
                {
                  key: 'Active experiences',
                  value:
                    creatorActivitySummary?.accountSummary?.activeExperiences?.current?.toString(),
                },
                {
                  key: 'Subscribers',
                  value:
                    creatorActivitySummary?.subscribers?.displayValue,
                },
                {
                  key: 'Sales',
                  value:
                    creatorActivitySummary?.sales?.displayValue,
                }
              ]}
            />
            <RecentlyCard recentlyEdited={creatorActivitySummary?.recentlyEdited} />
          </>
        )}
      </Box>
      <Card className="summary-data">
        <Box className="filters-wrapper" >
          <DateRangeSummaryFilter
            filterData={filterData}
            onSelected={(range) => {
              handleFilterDataChange('TIMERANGE', range)
            }}
          />
          <SummaryFilter
            type="BRAND"
            availableFilters={availableBrands}
            filterData={filterData}
            setFilterData={handleFilterDataChange}
          />
        </Box>
        <SummaryOverview charts={dashboardCharts} />
        <ExperienceStatisticsSummaryTable
          from={filterData.from}
          to={filterData.to}
          experienceId={filterData.experienceId}
          creatorId={filterData.creatorId}
          brandId={filterData.brandId}
          ownerType={"Business"}
          tableType={"Full"}
        />
      </Card>
    </Box>
  )
}

export default Dashboard
