import { Card } from '@mui/material'
import { TopUserChoice } from '../../../api/types'
import './SummaryTable.css'
import {FC} from "react";

const UserChoiceTable: FC<{
  userChoices: TopUserChoice[],
  rowsToShow: number,
  defaultRowsToShow: number,
  expand?: () => void,
  collapse?: () => void,
  type?: 'Regular' | 'Compact',
}> = ({
  userChoices,
  rowsToShow,
  defaultRowsToShow,
  expand,
  collapse,
  type = 'Regular',
}) => {
  return (
    <div>
      <Card className="summary-table" m={2}>
        <table>
          <thead
            className={`summary-table-header sticky-header`}
          >
            <tr>
              <th>Top user choices</th>
              <th>Clicks</th>
              {type == 'Regular' && <th>Page</th>}
            </tr>
          </thead>
          <tbody>
            {userChoices?.slice(0, rowsToShow).map(choice => {
              return (
                <tr key={choice?.cardId} className={type}>
                  { choice.product ? (
                    <td style={type == 'Compact' ? { justifyContent: 'left' } : {}}>
                      <img
                        className="table-avatar-square"
                        src={choice.product.images[0]}
                      />
                      <div>
                        <div>{choice.product.title}</div>
                        <div style={{ fontSize: "14px" }}>
                          <span>
                            {choice.product.price}
                          </span>
                          { choice.product.oldPrice && (
                            <span style={{ textDecoration: "line-through"}}>{choice.product.oldPrice}</span>
                          )}
                        </div>
                      </div>
                    </td>
                  ) : (
                    <td style={type == 'Compact' ? { justifyContent: 'left' } : {}}>
                      <img
                        className="table-avatar-square"
                        src={choice.cardImage}
                      />
                      { choice.tags?.length > 0 ? (
                          <div>{choice.tags.join(", ")}</div>
                      ) : choice?.directLinkLabel ? (
                          <div>{choice.directLinkLabel}</div>
                      ) : (
                          <div>{choice.campaignName}</div>
                      )}

                    </td>
                  )}
                  <td>{choice?.leads?.displayValue} clicks</td>
                  {type == 'Regular' && (
                    <td>
                      <a target="_blank" href={choice?.leadUrl}>
                        {choice?.leadUrl}
                      </a>
                    </td>
                  )}
                </tr>
              )
            })}
          </tbody>
          { (expand || collapse) && type == 'Regular' && (
            <tfoot>
            { defaultRowsToShow === rowsToShow && userChoices?.length > rowsToShow && expand && (
              <tr className="table-show-more">
                <td colSpan={5} onClick={expand}>
                  Show more
                </td>
              </tr>
            )}
            { defaultRowsToShow !== rowsToShow && collapse && (
              <tr className="table-show-more">
                <td colSpan={5} onClick={collapse}>
                  Show less
                </td>
              </tr>
            )}
            </tfoot>
          )}
        </table>
      </Card>
    </div>
  )
}

export default UserChoiceTable
