import {FC, useState} from "react";
import {DialogSimpleFieldText} from "../Reusable/DialogSimpleFieldText";
import ContentWrapper from "../ContentWrapper/ContentWrapper";
import {Button, Card, CardContent} from "@mui/material";
import {
  adminPlatformActionsCleanupMuxCloudAssets,
  adminPlatformActionsDeduplicateStatisticsAggregates,
  adminPlatformActionsDeleteProtectLivestreamRecordingPeriods,
  adminPlatformActionsReaggregateEmailTrackerEvents,
  adminPlatformActionsReaggregateSales,
  adminPlatformActionsRebuildStatisticsAggregates,
  adminPlatformActionsRebuildStatisticsAggregatesAll,
  adminPlatformActionsReloadExternalUrlValidations,
  adminPlatformActionsTriggerAwinTransactionFillingMissingValues, adminPlatformActionsTriggerExperienceWarmup,
  adminPlatformActionsTriggerMediaStorageItemVideoTranscoding,
  adminPlatformActionsTriggerVideoTranscodingMetadataRefetchAndProcessing
} from "../../api/services";


export const AdminPlatformActions: FC<{

}> = ({

}) => {

  const [parameter, setParameter] = useState<string>()

  const clear = () => {
    setParameter("")
  }

  return (
    <ContentWrapper title={"Platform actions"}>
      <Card>
        <CardContent>
          <DialogSimpleFieldText label={"Parameter"} value={parameter} setValue={setParameter} error={false}/>
          <Button variant={"contained"} sx={{margin: "5px"}} disabled={!parameter} onClick={() => adminPlatformActionsReloadExternalUrlValidations({ organizationId: parameter}).then(clear)}>Reload external link validations (organizationId)</Button>
          <Button variant={"contained"} sx={{margin: "5px"}} disabled={!parameter} onClick={() => adminPlatformActionsTriggerMediaStorageItemVideoTranscoding(parameter).then(clear)}>Trigger video processing (mediaStorageItemId)</Button>
          <Button variant={"contained"} sx={{margin: "5px"}} disabled={!parameter} onClick={() => adminPlatformActionsTriggerVideoTranscodingMetadataRefetchAndProcessing(parameter).then(clear)}>Trigger pipeline metadata processing (videoTranscodingPipelineId)</Button>
          <Button variant={"contained"} sx={{margin: "5px"}} disabled={!parameter} onClick={() => adminPlatformActionsRebuildStatisticsAggregates(parameter).then(clear)}>rebuildStatisticsAggregates (experienceId)</Button>
          <Button variant={"contained"} sx={{margin: "5px"}} disabled={!parameter} onClick={() => adminPlatformActionsRebuildStatisticsAggregatesAll(Number(parameter)).then(clear)}>rebuildStatisticsAggregatesAll (limit param, i.e. 100)</Button>
          <Button variant={"contained"} sx={{margin: "5px"}} onClick={() => adminPlatformActionsDeleteProtectLivestreamRecordingPeriods().then(clear)}>Trigger livestream recording statistics delete protection</Button>
          <Button variant={"contained"} sx={{margin: "5px"}} onClick={() => adminPlatformActionsDeduplicateStatisticsAggregates(parameter).then(clear)}>deduplicateStatisticsAggregates</Button>
          <Button variant={"contained"} sx={{margin: "5px"}} onClick={() => adminPlatformActionsTriggerAwinTransactionFillingMissingValues().then(clear)}>Trigger transaction data refilling</Button>
          <Button variant={"contained"} sx={{margin: "5px"}} onClick={() => adminPlatformActionsReaggregateSales().then(clear)}>Reaggregate sales</Button>
          <Button variant={"contained"} sx={{margin: "5px"}} onClick={() => adminPlatformActionsReaggregateEmailTrackerEvents().then(clear)}>Reaggregate email tracker events</Button>
          <Button variant={"contained"} sx={{margin: "5px"}} onClick={() => adminPlatformActionsCleanupMuxCloudAssets().then(clear)}>Cleanup mux cloud assets</Button>
          <Button variant={"contained"} sx={{margin: "5px"}} onClick={() => adminPlatformActionsTriggerExperienceWarmup(parameter).then(clear)}>Trigger experience warmup</Button>
        </CardContent>
      </Card>
    </ContentWrapper>
  )
}