import { Card } from '@mui/material'
import {FC} from 'react'
import { useHistory } from 'react-router'
import { TopPerformingExperience } from '../../../api/types'
import './SummaryTable.css'

const ExperienceTable: FC<{
  experiences: TopPerformingExperience[],
  rowsToShow: number,
  defaultRowsToShow: number,
  ownerType: "Creator" | "Business",
  expand?: () => void,
  collapse?: () => void,
}> = ({
  experiences,
  rowsToShow,
  defaultRowsToShow,
  ownerType,
  expand,
  collapse,
}) => {
  const router = useHistory()

  const handleSequencePollClick = (id: string) => {
    if (ownerType === "Creator") {
      router.push('/creators/experiences?id=' + id)
    } else {
      router.push('/content/experiences?id=' + id)
    }
  }

  return (
    <div className="summary-table-wrapper">
      <Card className="summary-table" m={2}>
        <table>
          <thead
            className={`summary-table-header sticky-header`}
          >
            <tr>
              <th>Top performing experiences</th>
              <th>Unique visitors</th>
              <th>Avg Time Spent</th>
              <th>Sales</th>
            </tr>
          </thead>
          <tbody>
            {experiences?.slice(0, rowsToShow).map(experience => {
              return (
                <tr key={experience?.id}>
                  <td
                    className="summary-table-clickable"
                    onClick={() => handleSequencePollClick(experience?.id)}
                  >
                    <img
                      className="table-avatar"
                      src={
                        experience.brand.imageLink
                      }
                    />
                    <div>
                      <div>
                        {experience?.campaignName}
                      </div>
                      <div style={{ fontSize: "14px" }}>
                        {experience.brand.title}
                      </div>
                    </div>
                  </td>
                  <td>{experience?.statistics.visitors?.displayValue}</td>
                  <td>
                    {experience?.statistics.averageTimeSpent?.displayValue}
                  </td>
                  <td>
                    {experience?.statistics?.transactionSummary?.totalSales?.displayValue}
                  </td>
                </tr>
              )
            })}
          </tbody>
          { (expand || collapse) && (
            <tfoot>
              { defaultRowsToShow === rowsToShow && experiences?.length > rowsToShow && expand && (
                <tr className="table-show-more">
                  <td colSpan={5} onClick={expand}>
                    Show more
                  </td>
                </tr>
              )}
              { defaultRowsToShow !== rowsToShow && collapse && (
                <tr className="table-show-more">
                  <td colSpan={5} onClick={collapse}>
                    Show less
                  </td>
                </tr>
              )}
            </tfoot>
          )}
        </table>
      </Card>
    </div>
  )
}

export default ExperienceTable
