import React, {FC, useEffect, useState} from "react";
import {Box, Button, Divider, Grid, Tooltip, Typography} from "@mui/material";
import {InlineImageSelector} from "../Reusable/InlineImageSelector";
import {GalleryItem} from "../Gallery/helpers";
import {DialogSimpleFieldText} from "../Reusable/DialogSimpleFieldText";
import {InlineCSVSelector} from "../Reusable/InlineCSVSelector";
import Papa from 'papaparse'
import {ExperiencePublisherShareValidationResult, Publisher} from "../../api/types";
import {
  mediaStorageGetSingle,
  sequencePollAwinPublisherShareSubmit,
  sequencePollAwinPublisherShareValidate,
  sequencePollGetPublisherShareMediaAssets,
  sequencePollUpdatePublisherShareMediaAssets
} from "../../api/services";
import {toast} from "react-toastify";
import InfoIcon from "@mui/icons-material/Info";
import SendIcon from '@mui/icons-material/Send';
import TaskIcon from '@mui/icons-material/Task';

export const ExperienceShareWithPublishers: FC<{
  onDismiss: () => void,
  experienceId: string,
}> = ({

  onDismiss,
  experienceId,
}) => {

  const [poster, setPoster] = useState<GalleryItem | null>(null)
  const [note, setNote] = useState<string>('')
  const [assetStorageLink, setAssetStorageLink] = useState<string>('')
  const [csvPublishers, setCsvPublishers] = useState<Publisher[]>()
  const [validationResponse, setValidationResponse] = useState<ExperiencePublisherShareValidationResult>()

  useEffect(() => {
    if (experienceId) {
      sequencePollGetPublisherShareMediaAssets(experienceId).then(d => {
        if (d.data.posterMediaStorageItemId) {
          mediaStorageGetSingle(d.data.posterMediaStorageItemId).then(m => {
            setPoster(m.data)
          })
        }
        if (d.data.description) {
          setNote(d.data.description)
        }
        if (d.data.assetStorageLink) {
          setAssetStorageLink(d.data.assetStorageLink)
        }
      })
    }
  }, [experienceId]);

  const onValidate = () => {

    sequencePollUpdatePublisherShareMediaAssets(experienceId, {
      experienceId: experienceId,
      description: note,
      posterMediaStorageItemId: poster?.id,
      assetStorageLink: assetStorageLink,
    }).then(() => {
      sequencePollAwinPublisherShareValidate(experienceId, {
        network: "Awin",
        experienceId: experienceId,
        publishers: csvPublishers,
      }).then(d => {
        setValidationResponse(d.data)
      })
    })

  }

  const onSubmit = () => {

    sequencePollAwinPublisherShareSubmit(experienceId, {
      network: "Awin",
      experienceId: experienceId,
      publishers: csvPublishers,
    }).then(d => {
      setValidationResponse(undefined)
      setCsvPublishers(undefined)
      toast.success("Publishers notified")
    })
  }

  const tooltip = (text) => {
    return (
      <Tooltip title={text}>
        <InfoIcon sx={{ position: "relative", top: "8px", marginLeft: "10px" }}/>
      </Tooltip>
    )
  }

  return (
    <>
      { experienceId && (
        <>
          {/*<Typography>*/}
          {/*  Each publisher will receive a unique affiliate link that they can use to bring traffic for and monetize this experience.*/}
          {/*</Typography>*/}
          <Divider/>
          <Typography sx={{fontSize: "12px"}}>

          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sx={{marginBottom: "24px"}}>
              <Box sx={{ maxWidth: "400px" }}>
                <InlineImageSelector
                  item={poster}
                  setItem={setPoster}
                  title={"Poster"}
                  type={'image/*'}
                  helpText={
                    <Typography sx={{color:"rgb(34, 51, 84)"}}>
                      Upload a poster to promote the video experience
                      {tooltip("This poster is meant to be displayed on the publisher channels, websites and email newsletter. It is recommended for it to look dynamic and interactive.")}
                    </Typography>}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <DialogSimpleFieldText
                label={"Add or paste text here"}
                value={note}
                setValue={setNote}
                error={false}
                multiline={true}
                helperText={
                <Typography sx={{color:"rgb(34, 51, 84)"}}>
                  Add a creative note to promote the video experience
                  {tooltip("Add description about the campaign message and define the creative copywriting that goes with the poster to promote this campaign.")}
                </Typography>
                }
              />
            </Grid>
            <Grid item xs={12}>
              <DialogSimpleFieldText
                label={"Paste the link here"}
                value={assetStorageLink}
                setValue={setAssetStorageLink}
                error={false}
                multiline={true}
                helperText={
                <Typography sx={{color:"rgb(34, 51, 84)"}}>
                  Add a storage link to share all the assets and descriptions (optional)
                </Typography>
                }
              />
            </Grid>
            <Grid item xs={12}>
              <InlineCSVSelector
                title={
                <Typography>
                  Add the list of your publishers receiving this campaign
                  {tooltip("Create a CSV file consisting of data in two columns. First column containing publisher ID and second column containing corresponding email address.")}
                </Typography>
                }
                onFileReady={(file) => {
                  Papa.parse(file, {
                    skipEmptyLines: true,
                    complete: function (results) {
                      let emailColumnIndex = 1
                      results.data.forEach(r => {
                        if (r[0].indexOf("@") > -1) {
                          emailColumnIndex = 0
                        } else if (r[1].indexOf("@") > -1) {
                          emailColumnIndex = 1
                        }
                      })
                      let publisherColumnIndex = emailColumnIndex == 1 ? 0 : 1
                      let publishers: Publisher[] = results.data.map(r => {
                        return { email: r[emailColumnIndex], publisherId: r[publisherColumnIndex] }
                      })
                      setCsvPublishers(publishers)
                    }
                  })
                }}
              />
            </Grid>
            { csvPublishers && csvPublishers.length > 0 && !validationResponse && (
              <Grid item xs={12}>
                <Typography sx={{ color: "#5569ff" }}>
                  Campaign will be sent to {csvPublishers.length} publishers
                </Typography>
              </Grid>
            )}
            { validationResponse && (
              <Grid item xs={12}>
                <Typography sx={{ color: "#5569ff" }}>
                  CSV contains {validationResponse.publishersTotal.length} publishers, out of which {validationResponse.publishersValidForImport.length + validationResponse.publishersValidForImportWithPlatformPublisherId.length} are valid to submit.
                </Typography>
              </Grid>
            )}
          </Grid>
        </>
      )}

      <Grid item xs={12} justifyContent={"end"} alignContent={"end"} container>
        <Button onClick={onValidate} disabled={!csvPublishers}>Validate<TaskIcon sx={{height: "18px", marginLeft: "5px"}}/></Button>
        <Button onClick={onSubmit} variant={"contained"} disabled={!validationResponse}>Submit<SendIcon sx={{height: "18px", marginLeft: "5px"}}/></Button>
      </Grid>
    </>
  )
}