import { HighlightOff } from '@mui/icons-material'
import {
  DashboardCharts,
  DashboardGetCreatorDataByRangeQueryParams,
} from '../../../api/types'
import { FilterType } from '../../../pages/Dashboard'
import './SequencePollAnalytics.css'
import {useAppContext} from "../../../contexts/PermissionContext";
import {Box} from "@mui/material";
import {
  ActiveUsersDataChart,
  ConversionDataChart, EmailEventsDataChart,
  PageViewsDataChart,
  SalesDataChart, SocialDataChart, SubscriptionsDataChart,
  TimeSpentDataChart,
  VisitorsDataChart
} from "../../Dashboard/Charts";
import {VisitorPieCharts} from "../../Dashboard/VisitorPieCharts";
import {DateRangeSummaryFilter} from "../../Dashboard/DateRangeSummaryFilter";
import {FC} from "react";
import {ExperienceStatisticsSummaryTable} from "../../Reusable/ExperienceStatisticsSummaryTable";

const SequencePollAnalytics: FC<{
  lastHourChartsData: DashboardCharts
  chartsData: DashboardCharts
  filterData: DashboardGetCreatorDataByRangeQueryParams
  handleFilterDataChange: (type: FilterType, value: any) => void
  showCloseButton?: boolean
  closeAnalytics?: () => void
  ownerType: "Creator" | "Business",
  experienceId: string,
}> = ({
  lastHourChartsData,
  chartsData,
  filterData,
  handleFilterDataChange,
  showCloseButton,
  closeAnalytics,
  ownerType,
  experienceId,
}) => {
  const { permissions } = useAppContext()
  return (
    <>
      {lastHourChartsData && (
        <Box sx={{marginTop: "10px"}}>
          <ActiveUsersDataChart data={lastHourChartsData} />
        </Box>
      )}
      <div className="analytics-header">
        <DateRangeSummaryFilter
          filterData={filterData}
          onSelected={(range) => {
            handleFilterDataChange('TIMERANGE', range)
          }}
        />
        {showCloseButton && (
          <div className="close-wrapper" onClick={closeAnalytics}>
            <HighlightOff />
          </div>
        )}
      </div>

      { chartsData && (
        <>
          <VisitorsDataChart data={chartsData} />
          <TimeSpentDataChart data={chartsData} />
          <PageViewsDataChart data={chartsData} />
          { (permissions?.organization?.sales || permissions?.creatorManagement?.sales) && (
            <>
              <SalesDataChart data={chartsData} liveStreamSales={true} />
              <ConversionDataChart data={chartsData} />
            </>
          )}
          <SubscriptionsDataChart data={chartsData} uniqueSubscribers={true} />
          <EmailEventsDataChart data={chartsData} />
          <SocialDataChart data={chartsData} />
          <VisitorPieCharts charts={chartsData} md={12}/>
        </>
      )}

      <ExperienceStatisticsSummaryTable
        creatorId={undefined}
        experienceId={experienceId}
        brandId={undefined}
        from={filterData.from}
        to={filterData.to}
        ownerType={ownerType}
        tableType={"UserChoices"}
      />
    </>
  )
}

export default SequencePollAnalytics
